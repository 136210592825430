import './Home.scss';
import {Tweet} from "./tweet";
import HeroImage from '../assets/hero.png'

function Home() {
  return <div className="Home">
      <header>
          <h1>
              A Dangerous Outlaw<span className='escape'>,</span><br/>
              Ruffling Feathers <br/>
              of Highfalutin<span className='escape'>'</span><br/>
              Establishments
          </h1>
          <img src={HeroImage} className="hero" alt="Elon Musk"/>
      </header>
      <div className="MDS">
          <h1>WANTED on Charges of</h1>
          <p className='charges'>
              1. Leading the Energy Transition<br/>
              2. Revolutionising Space Flight<br/>
              3. Defending Free Speech<br/>
              4. <span className='bad'>Political Views Inconvenient to the Establishment</span>
          </p>
          <hr/>
          <p>
              Vested interests threatened by Elon Musk are increasingly <strong
              className='unhinged'>unhinged</strong> in
              their attacks on him and his companies:
          </p>
          <div className="tweet-list">
              <Tweet id={'1770464334025535670'}
                     text='Elon Musk Is Everywhere—and It’s Too Much'
                     author='barronsonline'
                     date='20 Mar, 2024'/>
              <Tweet id={'1770320671521886649'}
                     text='Elon Musk Thinks Cannibals Are Invading the United States'
                     author='futurism'
                     date='20 Mar, 2024'/>
              <Tweet id={'1755941056103719087'}
                     text='Tesla Stock Rises for a Fourth Day. Why That Winning Streak Could Make It a Loser.'
                     author='barronsonline'
                     date='9 Feb, 2024'/>

          </div>
      </div>
      <div className='joinUs'>
          <h1>Help Us Fight Musk Derangement Syndrome</h1>
          <p>
              @-mention <a href='https://twitter.com/elonbaddotcom'>@ElonBadDotCom</a> when you see
              examples of Musk Derangement Syndrome on &#x1D54F;.
          </p>
          <p>
              We'll showcase the best examples here.
              {/*and voting the best onto our front page:*/}
          </p>
          <p>
              We have big plans for ElonBad.com in Q2 2024. Watch this space!
          </p>
          {/*<LoginButton/>*/}
      </div>
      <footer>
          <p>&#x1D54F; <a href='https://twitter.com/elonbaddotcom'>@ElonBadDotCom</a> #ElonBad</p>
      </footer>
  </div>
}

export default Home;
