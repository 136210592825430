export const Tweet = ({id, text, author, date}) =>
    <div className='tweet'>
        <div className='author'>
            <img className='avatar' src={`/avatars/${author}.jpg`}/>
            @{author}
        </div>
        <div className='content'>
            {text}
        </div>
        <div className='meta'>
            {date}
        </div>
        {/*<TwitterTweetEmbed*/}
        {/*    tweetId={id}*/}
        {/*    options={{width: 250, align: 'center'}}*/}
        {/*/>*/}
    </div>

